<template>
  <div class="footer">
    <div class="youqin">
      <a href="http://www.dplslab.com/">友情链接 : 北京智慧云测设备技术有限公司</a>
    </div>
    <div class="copy-right">
      <span>Copyright © 2021 江西智慧云测安全检测中心股份有限公司 All Rights Reserved</span>
    </div>
    <div class="gongan">
      <img src="../assets/res/ga.png" alt="">
      <span>
        <a href="https://beian.miit.gov.cn"> 备案号 : 赣ICP备2021005986号-1</a>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36060202000347"> | 赣公网安备 36060202000347号</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.footer {
  width: 100%;
  background: #409eff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 140px;
  .youqin {
    width: 100%;
    background: #409eff;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    a {
      color: #fff;
    }
  }
  .copy-right {
    padding-top: 15px;
    width: 100%;
    background: #409eff;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    a {
      color: #fff;
    }
  }
  .gongan {
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    a {
      color: #fff;
      margin-left: 10px;
    }
  }
}
</style>