<template>
  <div class="container">
    <el-carousel :interval="5000" height="550px" arrow="always">
      <el-carousel-item v-for="(item,index) in calist" :key="index">
        <!-- <h3>{{ item }}</h3> -->
        <img :src="item.src" :alt="item.title" style="height:100%;width:100%">
      </el-carousel-item>
    </el-carousel>
    <div class="main-content">
      <div class="m-box">
        <div class="title-warp">
          <div class="title-box">
            <div class="title">我们的服务</div>
            <span class="title-tip">Our services</span>
          </div>
        </div>
        <div class="ser-box">
          <ul class="core-main">
            <li class="core-list">
              <div class="core-img-one"></div>
              <div class="core-item-title">风险评估</div>
              <div class="core-item">基本信息分析</div>
              <div class="core-item">文件系统分析</div>
              <div class="core-item">安全等级评估</div>
              <div class="core-item"></div>
              <div class="core-item"></div>
            </li>
            <li class="core-list">
              <div class="core-img-two"></div>
              <div class="core-item-title">漏洞检索</div>
              <div class="core-item">固件漏洞检索</div>
              <div class="core-item">web漏洞检索</div>
              <div class="core-item">应用漏洞检索</div>
              <div class="core-item"></div>
              <div class="core-item"></div>
            </li>
            <li class="core-list">
              <div class="core-img-three"></div>
              <div class="core-item-title">安全隐患扫描</div>
              <div class="core-item">密码信息扫描</div>
              <div class="core-item">本地弱密码校验</div>
              <div class="core-item">敏感信息扫描</div>
              <div class="core-item">加密认证文件检索</div>
              <div class="core-item"></div>
            </li>
          </ul>
          <ul class="core-main">
            <li class="core-list">
              <div class="core-img-four"></div>
              <div class="core-item-title">自研学习知识库</div>
              <div class="core-item">CVE漏洞库</div>
              <div class="core-item">物联网安全漏洞库</div>
              <div class="core-item">敏感信息规则库</div>
              <div class="core-item">物联网应用组件数据库</div>
              <div class="core-item">安全特征库</div>
            </li>
            <li class="core-list">
              <div class="core-img-five"></div>
              <div class="core-item-title">深度剖析与深度测试</div>
              <div class="core-item">防火墙绕过检测</div>
              <div class="core-item">漏洞利用测试</div>
              <div class="core-item">深度剖析漏洞原理</div>
              <div class="core-item">Url日志列表导入扫描</div>
              <div class="core-item"></div>
            </li>
            <li class="core-list">
              <div class="core-img-six"></div>
              <div class="core-item-title">数据可视化</div>
              <div class="core-item">云端数据可视化</div>
              <div class="core-item">本地报告永久保存</div>
              <div class="core-item"></div>
              <div class="core-item"></div>
              <div class="core-item"></div>
            </li>
          </ul>

        </div>

      </div>
      <div class="m-box">
        <div class="title-warp">
          <div class="title-box">
            <div class="title">近期要闻</div>
            <span class="title-tip">Recent news</span>
          </div>
          <div class="more-detail">
            <el-button type="primary" plain round @click="newsMoreClick()">查看更多
              <i class="el-icon-right"></i>
            </el-button>
          </div>
        </div>

        <div class="card-container">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(item,index) in newsArr" :key="index">
              <div class="card-news" @click="cardClicked(item)">
                <div class="img-box">
                  <img class="img-cover" :src="item.article_img" alt="">
                </div>
                <div class="card-body">
                  <div class="title">
                    <span>新闻</span>
                    <span class="time">{{item.date}}</span>
                  </div>
                  <div class="content">
                    {{item.article_info}}
                  </div>
                </div>
                <div class="card-footer">
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="m-box">
        <div class="title-warp">
          <div class="title-box">
            <div class="title">成功案例</div>
            <span class="title-tip">Successful cases</span>
          </div>
          <div class="more-detail">
            <el-button type="primary" plain round @click="caseMoreClick()">查看更多
              <i class="el-icon-right"></i>
            </el-button>
          </div>
        </div>

        <div class="card-container">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(item,index) in sucCaseArr" :key="index">
              <div class="card-news" @click="cardClicked(item)">
                <div class="img-box">
                  <img class="img-cover" :src="item.article_img" alt="">
                </div>
                <div class="card-body">
                  <div class="title">
                    <span>案例</span>
                    <span class="time">{{item.date}}</span>
                  </div>
                  <div class="content">
                    {{item.article_info}}
                  </div>
                </div>
                <div class="card-footer">
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

    </div>

    <!-- 浮动广告 -->
    <div id="thediv" ref="thediv" class="mvad" @mouseover="clearFdAd" @mouseout="starFdAd"></div>
  </div>
</template>

<script>
import { article_list } from '@/api/server';
var interval;
export default {
  data () {
    return {
      calist: [
        {
          title: '',
          src: require('../assets/res/13.jpg')
        },
        {
          title: '',
          src: require('../assets/res/14.png')
        },
        {
          title: '',
          src: require('../assets/res/15.png')
        }
      ],
      newsArr: [],
      sucCaseArr: [],
      xPos: 300,
      yPos: 200,
      step: 1,
      delay: 8,
      height: 0,
      Hoffset: 0,
      Woffset: 0,
      yon: 0,
      xon: 0,
      pause: true
    }
  },
  components: {

  },
  methods: {
    cardClicked: function (item) {
      console.log(item);
      //   window.open(, '_blank');
      if (item.article_type == '2') {
        window.open(item.article_url, '_blank');
      } else {
        this.$router.push({ name: 'Article', params: { id: item.article_id } });
      }
    },
    newsMoreClick () {
      this.$router.push('/centerNews');
    },
    caseMoreClick () {
      this.$router.push('/successCase');
    },
    init () {
      var that = this;
      //获取中心动态新闻
      article_list({ classId: 18, start: 0, count: 4 }).then(res => {
        const { data } = res;
        that.newsArr = data.arr;
        console.log(that.newsArr);
      })

      //获取成功案例
      article_list({ classId: 20, start: 0, count: 4 }).then(res => {
        const { data } = res;
        that.sucCaseArr = data.arr;
        console.log(that.sucCaseArr);
      })
    },
    changePos () {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (this.$refs.thediv) {
        this.Hoffset = this.$refs.thediv.offsetHeight;
        this.Woffset = this.$refs.thediv.offsetWidth;
        this.$refs.thediv.style.left = (this.xPos + document.body.scrollLeft + document.documentElement.scrollLeft) + "px";
        this.$refs.thediv.style.top = (this.yPos + document.body.scrollTop + document.documentElement.scrollTop) + "px";
      }

      if (this.yon) {
        this.yPos = this.yPos + this.step;
      }
      else {
        this.yPos = this.yPos - this.step;
      }
      if (this.yPos < 0) {
        this.yon = 1;
        this.yPos = 0;
      }
      if (this.yPos >= (height - this.Hoffset)) {
        this.yon = 0;
        this.yPos = (height - this.Hoffset);
      }
      if (this.xon) {
        this.xPos = this.xPos + this.step;
      }
      else {
        this.xPos = this.xPos - this.step;
      }
      if (this.xPos < 0) {
        this.xon = 1;
        this.xPos = 0;
      }
      if (this.xPos >= (width - this.Woffset)) {
        this.xon = 0;
        this.xPos = (width - this.Woffset);
      }
    },
    clearFdAd () {
      clearInterval(interval)
    },
    starFdAd () {
      interval = setInterval(this.changePos, 10)
      // this.$once('hook:beforeDestroy', () => {
      //   clearInterval(interval)
      // })
    }
  },
  mounted () {
    this.init();
    interval = setInterval(this.changePos, 10);
  }
}
</script>

<style scoped lang="scss">
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 600px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

#thediv {
  z-index: 100;
  position: absolute;
  top: 43px;
  left: 2px;
  height: 150px;
  width: 150px;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  background: url(../assets/res/ad.png) no-repeat;
}

.main-content {
  width: 1200px;
  margin: 0 auto;

  .m-box {
    padding-top: 80px;
    &:last-child {
      padding-bottom: 80px;
    }

    .ser-box {
      padding-top: 66px;
      .core-main {
        width: 1210px;
        margin: 0 auto 100px;
        text-align: center;
        height: 245px;
        .core-list {
          float: left;
          width: 368px;
          background-color: #f7fafa;
          margin: auto 16px;
          position: relative;
          padding-top: 65px;
          padding-bottom: 30px;
          .core-item-title {
            font-size: 20px;
            color: #38b487;
            height: 30px;
            line-height: 30px;
            margin-bottom: 24px;
          }

          .core-item {
            font-size: 14px;
            height: 24px;
            line-height: 24px;
            color: #535353;
          }

          .core-img-one {
            background: url(../assets/res/tech_one.png) no-repeat;
            background-size: cover;
            height: 72px;
            width: 62px;
            position: absolute;
            top: -36px;
            left: 153px;
          }

          .core-img-two {
            background: url(../assets/res/tech_two.png) no-repeat;
            background-size: cover;
            height: 72px;
            width: 62px;
            position: absolute;
            top: -36px;
            left: 153px;
          }

          .core-img-three {
            background: url(../assets/res/tech_three.png) no-repeat;
            background-size: cover;
            height: 72px;
            width: 62px;
            position: absolute;
            top: -36px;
            left: 153px;
          }

          .core-img-four {
            background: url(../assets/res/tech_four.png) no-repeat;
            background-size: cover;
            height: 72px;
            width: 62px;
            position: absolute;
            top: -36px;
            left: 153px;
          }

          .core-img-five {
            background: url(../assets/res/tech_five.png) no-repeat;
            background-size: cover;
            height: 72px;
            width: 62px;
            position: absolute;
            top: -36px;
            left: 153px;
          }

          .core-img-six {
            background: url(../assets/res/tech_six.png) no-repeat;
            background-size: cover;
            height: 72px;
            width: 62px;
            position: absolute;
            top: -36px;
            left: 153px;
          }
        }
      }
    }

    .title-warp {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 30px;
      border-bottom: 1px solid #e3e4e8;
      .title-box {
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .title {
          font-size: 32px;
          font-weight: 500;
        }

        .title-tip {
          font-size: 12px;
          color: #666;
          text-align: left;
        }
      }
    }

    .card-container {
      padding-top: 20px;
      .card-news:hover {
        margin-top: -2px;
        box-shadow: 0px 0px 5px #007aff;
        .card-footer {
          color: #007aff;
        }
      }
      .card-news {
        padding: 17px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: #666;
        background-color: #fff;
        .img-box {
          width: 100%;
          height: 190px;
          .img-cover {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .card-body {
          padding: 16px;
          padding-bottom: 0px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          text-align: left;
          .title {
            margin-bottom: 10px;
            .time {
              border-left: 1px solid #d9d9d9;
              padding-left: 8px;
              margin-left: 8px;
            }
          }
          .content {
            position: relative;
            font-size: 16px;
            line-height: 1.5;
            height: 48px;
            margin-bottom: 8px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            // word-wrap: break-word;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // &:after {
            //   content: '...';
            //   position: absolute;
            //   bottom: 0;
            //   right: 8px;
            //   padding-left: 30px;
            //   background: -webkit-linear-gradient(left, transparent, #fff 50%);
            //   background: -o-linear-gradient(right, transparent, #fff 50%);
            //   background: -moz-linear-gradient(right, transparent, #fff 50%);
            //   background: linear-gradient(to right, transparent, #fff 50%);
            // }
          }
        }
        .card-footer {
          color: #999;
          text-align: right;
          width: 90%;
          padding: 0px 9px;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
