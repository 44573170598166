
import request from "@/utils/request";

export function test () {
  return request({
    url: 'Public/get_test',
    method: 'get'
  })
}

export function article_list (data) {
  return request({
    url: "/Public/get_article_list",
    method: "post",
    data
  });
}

export function article (data) {
  return request({
    url: "/Public/get_article",
    method: "post",
    data
  });
}

//登录
export function login (data) {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  };
  return request({
    url: "/WebPublic/login",
    method: "post",
    data,
    headers: headers,
  });
}

//请求刷新token
export function tokenRefresh (params) {
  return request({
    url: "/auth/login",
    method: "get",
    params,
  });
}

//请求显示校验码
export function showCodeImg () {
  return request({
    url: "/code/showCodeImg",
    method: "get"
  });
}

export function sendSmsCode (data) {
  return request({
    url: "/WebPublic/send_verify",
    method: "post",
    data
  })
}

export function register (data) {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  };
  return request({
    url: "/WebPublic/register",
    method: "post",
    data,
    headers: headers,
  });
}

//获取首页教师列表
export function getIndexTeacher () {
  return request({
    url: 'WebPublic/get_index_teacher',
    method: 'get'
  })
}

//获取课程列表
export function getLessonList (data) {
  return request({
    url: 'WebPublic/get_lesson_list',
    method: 'post',
    data
  })
}

//根据课程获取课时列表
export function getPeriodList (data) {
  return request({
    url: 'WebPublic/get_period_list',
    method: 'post',
    data
  })
}

//根据课时获取课时详细信息
export function getPeriodInfo (data) {
  return request({
    url: 'WebMember/get_period_info',
    method: 'post',
    data
  })
}

//根据视频标识获取视频url
export function getResUrl (data) {
  return request({
    url: 'WebMember/get_res_url',
    method: 'post',
    data
  })
}

//需要校验
export function getMineInfo () {
  return request({
    url: "/WebMember/member_info",
    method: 'get'
  })
}

//需要校验
export function saveMineInfo (data) {
  return request({
    url: "/WebMember/save_member_info",
    method: 'post',
    data
  })
}

//保存记录
export function saveSchedule (data) {
  return request({
    url: "/WebMember/save_schedule",
    method: 'post',
    data
  })
}

//获取记录
export function getSchedule (data) {
  return request({
    url: "/WebMember/get_video_schedule",
    method: 'post',
    data
  })
}

//获取购买记录
export function getBuyRecord (data) {
  return request({
    url: "/WebMember/get_buy_record",
    method: 'post',
    data
  })
}

export function payOrder (data) {
  return request({
    url: "/WebMember/pay_order",
    method: 'post',
    data
  })
}

//新房列表
export function showNewHouseByPage (data) {
  let headers = {
    "Content-Type": "application/json;charset=UTF-8",
  };
  return request({
    url: "/housingEstate/showNewHouseByPage",
    baseURL: "http://www.yujwang.com:8082/api",
    method: "post",
    data,
    headers: headers,
  });
}