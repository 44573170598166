import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/centerNews',
    name: 'CenterNews',
    component: () => import('../views/CenterNews.vue')
  },
  {
    path: '/leaderCare',
    name: 'LeaderCare',
    component: () => import('../views/LeaderCare.vue')
  },
  {
    path: '/successCase',
    name: 'SuccessCase',
    component: () => import('../views/SuccessCase.vue')
  },
  {
    path: '/policRule',
    name: 'PolicRule',
    component: () => import('../views/PolicRule.vue')
  },
  {
    path: '/fileDown',
    name: 'FileDown',
    component: () => import('../views/FileDown.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/hornor',
    name: 'Hornor',
    component: () => import('../views/Hornor.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/Job',
    name: 'About',
    component: () => import('../views/Job.vue')
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import('../views/Article.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
