module.exports = {
  baseUrl: {
    serverUrl: "https://www.dplslab-jx.com/zhyadmin/index.php/api/",
    payServerUrl: "https://www.dplslab-jx.com/zhyadmin/index.php/api/",
    imgServerUrl: "https://www.dplslab-jx.com/zhyadmin/index.php/api/",
    bussinessUrl: "https://www.dplslab-jx.com/zhyadmin/index.php/api/"
  },
  requestTimeout: 8000, //最长请求时间
  successCode: 0, //操作正常code
  invalidCode: 402, //登录失效code
  errorCode: 500, //系统异常code
  noPermissionCode: 401, //无权限code
  messageDuration: 2000, //消息框消失时间
}