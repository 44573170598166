<template>
  <div class="header-container">
    <div class="header-warp">
      <div class="header-logo"></div>
      <div class="header-nav">
        <el-menu :default-active="$route.path" router class="el-menu-demo" mode="horizontal" active-text-color="#409EFF" @select="handleSelect">
          <el-menu-item index="/">
            网站首页
          </el-menu-item>
          <el-menu-item index="/centerNews">
            中心动态
          </el-menu-item>
          <el-menu-item index="/leaderCare">
            领导关怀
          </el-menu-item>
          <el-menu-item index="/successCase">
            成功案例
          </el-menu-item>
          <el-menu-item index="/policRule">
            政策法规
          </el-menu-item>
          <el-menu-item index="/fileDown">
            资料下载
          </el-menu-item>
          <el-submenu index="7" id="about">
            <template slot="title">关于我们</template>
            <el-menu-item index="/about">
              公司简介
            </el-menu-item>
            <el-menu-item index="/contact">
              合作咨询
            </el-menu-item>
            <el-menu-item index="/hornor">
              资质荣誉
            </el-menu-item>
            <el-menu-item index="/job">诚聘英才</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      activeIndex: '1'
    }
  },
  components: {

  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style scoped lang="scss">
.header-container {
  width: 100%;
  background: #fff;
  .header-warp {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .header-logo {
      display: block;
      width: 289px;
      height: 75px;
      background: url(../assets/res/logo.png) no-repeat 0 0;
    }

    .header-nav {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
}
</style>
<style>
.el-menu-item,
.el-submenu__title {
  font-size: 18px !important;
  color: #333;
  font-weight: 400;
}
</style>

